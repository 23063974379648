import styled from 'styled-components';
import React from 'react';
import { Body } from '../Text';
import theme from '../../theme';

export type Size = 'small' | 'normal';

export const PrimaryCTA = (props: {
    onClick: () => void;
    size: Size;
    style?: React.CSSProperties;
    label?: string;
    sublabel?: string;
    isFooter?: boolean;
}) => {
    return (
        <LoginButtonStyle
            onClick={props.onClick}
            size={props.size}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                ...props.style,
            }}
        >
            <BodyStyle>{props.label || 'Start for free'}</BodyStyle>
            {props.sublabel ? (
                <div
                    style={{
                        fontWeight: 'light',
                    }}
                >
                    {'  '} - {props.sublabel}
                </div>
            ) : null}
        </LoginButtonStyle>
    );
};

export const BodyStyle = styled(Body)`
    font-weight: bold !important;
    font-size: 1.2rem !important;
`;

export const LoginButtonStyle = styled.button<{ size: Size }>`
    color: ${theme.colors.white};
    text-decoration: none;
    border-radius: ${theme.roundness}px;
    border: none;
    background-color: ${theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    width: ${({ size }) => (size === 'small' ? 'initial' : '100%')};
    padding: ${({ size }) => (size === 'small' ? 'initial' : '16px 16px')};

    &:hover {
        text-decoration: none;
        color: ${theme.colors.white};
        background-color: ${theme.colors.secondary};
    }
`;
