import { useSearchParams } from 'react-router-dom';
import { PreSignupSurveyScreen } from '../../../screens/Onboarding/PreSignupSurvey/context';
import { SectionHowItWorks } from '../elements/SectionHowItWorks';
import { SectionTrustedBy } from '../elements/SectionTrustedBy';
import { SectionsTitle } from '../elements/SectionsTitle';
import { SectionReviewsCarousel } from '../elements/SectionReviewsCarousel';
import { SectionReviewsVertical } from '../elements/SectionReviewsVertical';
import { PricingTable } from '../elements/PricingTable';
import SinglePricingPlan from '../elements/SinglePricingPlan';
import { PageVariation, VariationConfig } from './types';

export const useConfigToPageVariation = (
    config: VariationConfig,
    baseName: string,
    variationName: string
): PageVariation => {
    const [searchParams] = useSearchParams();

    let header = '';
    let description = '';
    if (typeof config.header === 'string') header = config.header;
    else header = config.header(searchParams);

    if (typeof config.description === 'string')
        description = config.description;
    else description = config.description(searchParams);

    return {
        baseName,
        variationName,
        header: {
            type: 'TextElement',
            text: header,
            render: true,
        },
        headerMode: config?.headerMode || 'signup',
        description: {
            type: 'TextElement',
            text: description,
            render: true,
        },
        mainVisual: config.primaryVisualComp
            ? {
                  type: 'ImageElementComponent',
                  render: Boolean(config.primaryVisualComp),
                  component: config.primaryVisualComp,
              }
            : {
                  type: 'ImageElementUrl',
                  render: Boolean(config.primaryVisualUrl),
                  url: config.primaryVisualUrl ?? '',
                  alt: config.primaryVisualAlt ?? '',
              },
        secondaryVisual: config.secondaryVisualComp
            ? {
                  type: 'ImageElementComponent',
                  render: Boolean(config.primaryVisualComp),
                  component: config.secondaryVisualComp as any,
              }
            : {
                  type: 'ImageElementUrl',
                  render: Boolean(config.secondaryVisualUrl),
                  url: config.secondaryVisualUrl ?? '',
                  alt: config.secondaryVisualAlt ?? '',
              },
        callToAction: {
            type: 'ButtonElement',
            render: true,
            label: config.heroCallToAction.label,
            sublabel: config.heroCallToAction.sublabel,
            action: config.heroCallToAction.action ?? 'StartForFree',
            icon: config.heroCallToAction.showIcon ?? true,
            assignedPosition: 'hero',
        },
        tabCallToAction: config.tabCallToAction
            ? {
                  type: 'ButtonElement',
                  render: true,
                  label: config.tabCallToAction.label,
                  sublabel: config.tabCallToAction.sublabel,
                  action: config.tabCallToAction.action ?? 'StartForFree',
                  icon: config.tabCallToAction.showIcon ?? true,
                  assignedPosition: 'tab',
              }
            : null,
        finalCallToAction: config.finalCallToAction
            ? {
                  type: 'ButtonElement',
                  render: true,
                  label: config.finalCallToAction.label,
                  sublabel: config.finalCallToAction.sublabel,
                  action: config.finalCallToAction.action ?? 'StartForFree',
                  icon: config.finalCallToAction.showIcon ?? true,
                  assignedPosition: 'final',
              }
            : undefined,
        reviews: config.reviews
            ? { component: <SectionReviewsCarousel padding={0} /> }
            : { component: null },
        reviewsVertical: config.reviewsVertical
            ? {
                  component: (
                      <SectionReviewsVertical {...config.reviewsVertical} />
                  ),
              }
            : { component: null },
        pricing: config.pricing
            ? {
                  component: (
                      <PricingTable
                          action={config.pricingCallToAction?.action}
                      />
                  ),
              }
            : { component: null },
        singlePricing: config.singlePricing
            ? {
                  component: (
                      <SinglePricingPlan
                          price={config.singlePricing.price}
                          bulletPoints={config.singlePricing.bulletPoints}
                      />
                  ),
              }
            : { component: null },
        howItWorks: config.howItWorks
            ? { component: <SectionHowItWorks /> }
            : { component: null },
        trustedBy: config.trustedBy
            ? { component: <SectionTrustedBy /> }
            : { component: null },
        sectionsTitle: config.sectionsTitle
            ? {
                  component: (
                      <>
                          <SectionsTitle title={config.sectionsTitle} />
                      </>
                  ),
              }
            : { component: null },
        finalCallToActionHeader: config.finalCallToActionHeader,

        sections: config.sections,

        onboardingIntroductions: config.onboardingIntroductions ?? false,
        onboardingIntroductionReport:
            config.onboardingIntroductionReport ?? false,
        onboardingPresignupInitialScreen:
            config.onboardingPresignupInitialScreen ??
            PreSignupSurveyScreen.Goals,
        onboardingUsageSurveryDisplayQuestion:
            config.onboardingUsageSurveryDisplayQuestion ?? undefined,
        onboardingPresignupExtendedSurvey:
            config.onboardingPresignupExtendedSurvey ?? false,
        onboardingShowRecommendedPlan:
            config.onboardingShowRecommendedPlan ?? false,
        directPaywall: config.onboardingIntroductionReport ?? false,

        redirectBranch: config.redirectBranch ?? false,
        redirectUrl: config.redirectUrl ?? undefined,
    };
};
