import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { API_BASE_URL, GOOGLE_CLIENT_ID } from '../../configuration';
import { useAuth } from '../../providers/AuthProvider';
import {
    onWebAppOnboardingConnectGmail,
    onWebAppOnboardingGmailErrorFound,
    onWebAppOnboardingGmailTokenFound,
} from '../../tracking/trackers';
import { useQuery } from '../../util/hooks';

const useConnectEmail = (
    onConnectedEmail: () => void,
    uri?: string,
    options?: {
        acquired_from?: string;
        only_if_existent?: boolean;

        max_receipts?: number;
        after?: string;
        source?: string;
    },
    alternativeScope?: string[]
) => {
    const query = useQuery();
    const email = query.get('email'); // is defined when google oauth is complete
    const error = query.get('error'); // is defined when google oauth has an error

    const { user } = useAuth();

    useEffect(() => {
        if (email !== null) {
            onWebAppOnboardingGmailTokenFound();
            onConnectedEmail();
        }

        if (error !== null) {
            onWebAppOnboardingGmailErrorFound();
        }
    }, [email]);

    const startGoogleOauth = () => {
        LogRocket.track('ConnectEmail');
        onWebAppOnboardingConnectGmail();
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const redirectUri = uri || 'api/v2/auth/oauth/google';

        const scope = (
            alternativeScope || [
                'https://www.googleapis.com/auth/gmail.readonly',
            ]
        ).join(' ');

        // const rUri = `http://localhost:8889/${redirectUri}`; // USED FOR TESTING ONLY!!
        const rUri = `${API_BASE_URL}/${redirectUri}`;

        const currentUrlNoQueryParams = window.location.href.split('?')[0];

        const params = {
            response_type: 'code',
            client_id: GOOGLE_CLIENT_ID,
            redirect_uri: rUri,
            prompt: 'consent',
            access_type: 'offline',
            include_granted_scopes: 'true',
            state: JSON.stringify({
                domain: currentUrlNoQueryParams, // we'll redirect here after oauth login
                user_id: user?.id,
                max_receipts: 100,
                auto_commit: true,
                import_method: 'async',
                ...options,
            }),
            scope,
        };

        const urlParams = new URLSearchParams(params).toString();
        const url = `${googleAuthUrl}?${urlParams}`;
        window.location.replace(url);
    };

    return { startGoogleOauth };
};

export default useConnectEmail;
