import { ReactElement } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import theme from '../../theme';
import BackgroundImage from '../../assets/onboardingBackground.png';
import LogoWhite from '../../assets/landing/logo_white.svg';

export const ONBOARDING_HEADER_HEIGHT = 80;

// When using this, should render the child component in the "OnboardingCardTemplate" template
const OnboardingBackground = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const { width } = useWindowDimensions();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width,
            }}
        >
            <div
                style={{
                    height: ONBOARDING_HEADER_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <img
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        width: 120,
                    }}
                    src={LogoWhite}
                    alt={''}
                />
            </div>
            <div
                style={{
                    height: '100%',
                    borderTopLeftRadius: 40,
                    borderTopRightRadius: 40,
                    backgroundColor: theme.colors.white,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default OnboardingBackground;
