import React, { createContext, useContext, useState } from 'react';
import {
    CELL_HEIGHT,
    COLUMNS,
    IColumn,
    SPREADSHEET_IMAGE_SECTION_WIDTH,
    getShowSpreadsheetImage,
} from '../FolderSpreadsheet/constants';
import useLocalStoragePrefs from '../FolderSpreadsheet/useLocalStoragePrefs';
import { useSharedReview } from '../../screens/SharedReviewV2/context';
import { number } from '../../util/devices';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const SpreadsheetContext = createContext<{
    columns: IColumn[];
    setColumns: React.Dispatch<React.SetStateAction<IColumn[]>>;
    spreadsheetWidth: number;
    headerWidth: number;
    spreadsheetContainerWidth: number;
    includeEmployeeColumn: () => void;
}>({
    columns: [],
    setColumns: () => {},
    spreadsheetWidth: 0,
    headerWidth: 0,
    spreadsheetContainerWidth: 0,
    includeEmployeeColumn: () => {},
});

export const ADD_COLUMN_BUTTON_WIDTH = CELL_HEIGHT;

/**
 * Stores props that we want access to from the spreadsheet header (which would normally be in the body)
 */
const SpreadsheetProvider = ({
    source,
    sharedReviewId,
    children,
}: {
    source: 'report' | 'inbox' | 'spreadsheet';
    sharedReviewId?: string;
    children: React.ReactNode;
}) => {
    const { width } = useWindowDimensions();
    // Note: This may not be inside of SharedReviewProvider, so might be undefined
    const sharedReviewContext = useSharedReview() ?? { tab: undefined };
    const tab = sharedReviewContext?.tab || (undefined as string | undefined);
    const summaryWidth = sharedReviewContext?.summaryWidth || 0;
    const isSummaryTab = !!sharedReviewId && tab === 'summary';

    const { data: localStoragePrefs } = useLocalStoragePrefs(sharedReviewId);
    const isShowingImage = getShowSpreadsheetImage(width);

    let defaultColumns = localStoragePrefs?.columns
        ? localStoragePrefs.columns
        : COLUMNS.filter(({ isDefault }) => !!isDefault);
    const platformColumn = COLUMNS.find((c) => c.key === 'platform');
    if (source === 'inbox') {
        // bc you can't save reconciled on processing receipts
        defaultColumns = defaultColumns.filter((c) => !c.disableInInbox);
    }
    if (source === 'inbox' && platformColumn) {
        if (!defaultColumns.find((c) => c.key === 'platform')) {
            defaultColumns = [
                ...defaultColumns.splice(0, 1),
                platformColumn,
                ...defaultColumns.splice(0),
            ];
        }
        defaultColumns = defaultColumns.filter((c) => c.key !== 'folder');
        defaultColumns = [
            ...defaultColumns.splice(0, 1),
            COLUMNS.find((c) => c.key === 'folder')!,
            ...defaultColumns.splice(0),
        ];
    } else {
        // filter out platform column
        defaultColumns = defaultColumns.filter((c) => c.key !== 'platform');
    }

    const [columns, setColumns] = useState<IColumn[]>(defaultColumns);

    const includeEmployeeColumn = () => {
        setColumns((prevColumns) => {
            const employeeColumn = COLUMNS.find((c) => c.key === 'employee');
            if (
                employeeColumn &&
                !prevColumns.find((c) => c.key === 'employee')
            ) {
                return [
                    ...prevColumns.splice(0, 1),
                    employeeColumn,
                    ...prevColumns.splice(0),
                ];
            }
            return prevColumns;
        });
    };

    const spreadsheetImageSectionWidth =
        isShowingImage && !isSummaryTab ? SPREADSHEET_IMAGE_SECTION_WIDTH : 0;

    const spreadsheetWidth =
        columns.map((c) => c.width).reduce((a, b) => a + b) +
        ADD_COLUMN_BUTTON_WIDTH;

    const spreadsheetContainerWidth = Math.min(
        spreadsheetWidth,
        width - spreadsheetImageSectionWidth
    );

    const headerIsFullWidth = width < number.LAPTOP;
    const headerWidth = isSummaryTab
        ? Math.min(width, summaryWidth + 20)
        : headerIsFullWidth
        ? width
        : spreadsheetContainerWidth + spreadsheetImageSectionWidth;

    return (
        <SpreadsheetContext.Provider
            value={{
                columns,
                setColumns,
                spreadsheetWidth,
                spreadsheetContainerWidth,
                headerWidth,
                includeEmployeeColumn,
            }}
        >
            {children}
        </SpreadsheetContext.Provider>
    );
};

export default SpreadsheetProvider;

export const useSpreadsheet = () => useContext(SpreadsheetContext);
