import { useNavigate } from 'react-router-dom';
import { CallToActions } from '../configuration/types';
import { APP_STORE_LINK } from '../../../util/constants';

export const useCTAAction = (action?: CallToActions | undefined) => {
    const navigate = useNavigate();
    switch (action) {
        case 'StartForFree':
            return () => navigate('/welcome/sign-up');
        case 'StartForFreeManualCodeEntry':
            return () => navigate('/welcome/sign-up?manualCodeEntry=true');
        case 'TeamsTypeform':
            return () =>
                window.open('https://szs1ainjklw.typeform.com/to/k8B1sZI2');
        case 'TeamsCollectionSignup':
            return () =>
                navigate('/signup?manualCodeEntry=true&variant=texting');
        case 'StartForFreeForSalesTaxOnboarding':
            return () => navigate('/welcome/salestaxonb/quiz');
        case 'CostEstimation':
            return () =>
                navigate(
                    `/welcome/cost-estimation?initial-landing=${window.location.pathname}`
                );
        case 'DownloadApp':
            return () => window.open(APP_STORE_LINK, '_blank');
        case 'DownloadPage':
            return () => navigate('/download');
        case 'StartTeams':
            return () =>
                navigate('/signup?manualCodeEntry=true&variant=organization');
        default:
            return () => navigate('/welcome/sign-up');
    }
};
