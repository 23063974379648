import { createContext, useContext, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
    ILineItem,
    IProcessingCommitData,
    IProcessingLineItem,
    IUserTag,
} from '../../services/receipts/types';
import { useDeepCompareEffect } from '../../util/hooks';
import ReceiptService from '../../services/receipts/service';
import { onWebAppSpreadsheetBulkDelete } from '../../tracking/trackers';
import { useSpaces } from '../../services/spaces/context';
import { Space } from '../../services/spaces/types';
// import * as Validators from '../../util/validators';
import { useFolders } from '../ReceiptsHome/folderContext';
import { useSpreadsheet } from '../../components/SpreadsheetView/context';
import { ISnackbarState } from '../../providers/SnackbarProvider';
import { IInboxReceipt } from './types';
import {
    useProcessingInboxReceipts,
    useUncategorizedInboxReceipts,
} from './selectors';

type IInboxContext = {
    inboxReceipts: Map<string, IInboxReceipt>;
    setInboxReceipts: React.Dispatch<
        React.SetStateAction<Map<string, IInboxReceipt>>
    >;
    isSaving: boolean;
    isLoadingInboxReceipts: boolean;
    deleteReceipts: (receiptIds: string[]) => Promise<void>;
    editReceipt: (
        receiptId: string,
        payload: any
    ) => Promise<IInboxReceipt | void>;
    commitReceipt: (receiptId: string) => Promise<void>;
    getLineItems: (
        receiptId: string
    ) => Promise<(ILineItem | IProcessingLineItem)[]>;
    editLineItems: (
        receiptId: string,
        lineItems: (ILineItem | IProcessingLineItem)[]
    ) => Promise<void>;
    addLineItem: (
        receiptId: string
    ) => Promise<ILineItem | IProcessingLineItem | undefined>;
    folders: IUserTag[];
    spaces: Space[];
    handleSaveWhenDefined: ISnackbarState | undefined;
    refetch: () => Promise<void>;
    processingLineItemChanges: { [receiptId: string]: IProcessingLineItem[] };
};

const InboxContext = createContext<IInboxContext>({
    inboxReceipts: new Map(),
    setInboxReceipts: () => {},
    isLoadingInboxReceipts: false,
    isSaving: false,
    handleSaveWhenDefined: undefined,
    deleteReceipts: () => Promise.resolve(),
    editReceipt: () => Promise.resolve(),
    commitReceipt: () => Promise.resolve(),
    getLineItems: () => Promise.resolve([]),
    editLineItems: () => Promise.resolve(),
    addLineItem: () => Promise.resolve(undefined),
    folders: [],
    spaces: [],
    refetch: () => Promise.resolve(),
    processingLineItemChanges: {},
});

export const InboxProvider = (props: { children: React.ReactNode }) => {
    const {
        receipts: inboxProcessingReceipts,
        isLoading: isLoadingProcessing,
    } = useProcessingInboxReceipts() || [];
    const {
        receipts: inboxStoredReceipts,
        isLoading: isLoadingUncategorized,
        refetch,
    } = useUncategorizedInboxReceipts();
    const navigate = useNavigate();
    const { includeEmployeeColumn } = useSpreadsheet();
    const isLoadingInboxReceipts =
        isLoadingProcessing || isLoadingUncategorized;

    const { folders } = useFolders();
    const { spaces } = useSpaces();

    const [inboxReceipts, setInboxReceipts] = useState<
        Map<string, IInboxReceipt>
    >(new Map());
    const [processingLineItemChanges, setProcessingLineItemChanges] = useState<{
        [receiptId: string]: IProcessingLineItem[];
    }>({});
    const [isSaving, setIsSaving] = useState(false);
    const [handleSaveWhenDefined, setHandleSaveWhenDefined] = useState<
        ISnackbarState | undefined
    >(undefined);

    useEffect(() => {
        const someDocumentHasEmployeeField = Array.from(
            inboxReceipts.values()
        ).some((receipt) => {
            return receipt?.additional_fields?.external;
        });
        if (someDocumentHasEmployeeField) {
            includeEmployeeColumn();
        }
    }, [inboxReceipts.size]);

    const assignSuggestedFoldersToReceipts = async (
        receipts: [string, IInboxReceipt][]
    ) => {
        await Promise.all(
            receipts.map(async ([, receipt]) => {
                const { data } = await ReceiptService.getCategorySuggestion(
                    receipt.merchant || '',
                    receipt.receipt_id
                );
                if (data?.category) receipt.categories = [data?.category];
            })
        );
        setInboxReceipts((prev) =>
            receipts.reduce((acc, [key, receipt]) => {
                acc.set(key, {
                    ...receipt,
                    animate:
                        receipt.categories && receipt.categories.length > 0,
                });
                return acc;
            }, new Map(prev))
        );
    };

    useDeepCompareEffect(() => {
        const typedProcessing = [
            ...inboxProcessingReceipts?.map((r) => {
                return { ...r, type: 'processing' };
            }),
        ] as IInboxReceipt[];
        const typedStored = [
            ...inboxStoredReceipts?.map((r) => {
                return { ...r, type: 'stored' };
            }),
        ] as IInboxReceipt[];

        const _inboxReceipts = typedProcessing
            .concat(typedStored)
            .sort(
                (a, b) =>
                    new Date(b.date || b.created_at || '').getTime() -
                    new Date(a.date || a.created_at || '').getTime()
            )
            .reduce(
                (acc, receipt) => acc.set(receipt.receipt_id, receipt),
                new Map<string, IInboxReceipt>()
            );
        setInboxReceipts(_inboxReceipts);
        const iterableInboxReceipts = Array.from(_inboxReceipts);
        const recentReceipts = iterableInboxReceipts
            .filter(
                ([, r]) =>
                    new Date(r.created_at || '').getTime() >=
                    new Date().getTime() - 1000 * 60 * 10
            )
            .slice(0, 5);
        if (recentReceipts.length > 0) {
            assignSuggestedFoldersToReceipts(recentReceipts);
        }
    }, [inboxProcessingReceipts, inboxStoredReceipts]);

    const getSelectedInGroups = (receiptIds: string[]) => {
        const selectedProcessings: string[] = [];
        const selectedStored: string[] = [];
        for (const receiptId of receiptIds) {
            const receipt = inboxReceipts.get(receiptId);
            if (!receipt) continue;
            if (receipt.type === 'processing')
                selectedProcessings.push(receiptId);
            else selectedStored.push(receiptId);
        }
        return { selectedProcessings, selectedStored };
    };

    const bulkDeleteProcessingReceipts = async (receiptIds: string[]) => {
        if (!receiptIds.length) return;
        await ReceiptService.bulkDeleteProcessingReceipts({
            receiptIds,
        });
    };

    const bulkDeleteStoredReceipts = async (receiptIds: string[]) => {
        if (!receiptIds.length) return;
        await ReceiptService.bulkDelete({ receiptIds });
    };

    const deleteReceipts = async (receiptIds: string[]) => {
        try {
            onWebAppSpreadsheetBulkDelete({
                count: receiptIds.length,
                source: 'inbox',
            });
            const { selectedProcessings, selectedStored } =
                getSelectedInGroups(receiptIds);
            bulkDeleteProcessingReceipts(selectedProcessings);
            bulkDeleteStoredReceipts(selectedStored);
        } catch (err) {
            console.error('Error deleting receipts', err);
        }
    };

    const editReceipt = async (
        receiptId: string,
        payload: any
    ): Promise<IInboxReceipt | undefined> => {
        const receipt = inboxReceipts.get(receiptId);
        if (!receipt) return;
        if (receipt.type === 'processing') {
            // do nothing, since just the UI needs to update, which is handled in the spreadsheet
            // unless we're changing the categories, then we need to update the line items
            if (payload.tag_ids) {
                const currentLineItems =
                    processingLineItemChanges[receiptId] ||
                    (await getLineItems(receiptId));
                const newlyCategorizedLineItems = currentLineItems.map((li) => {
                    return {
                        ...li,
                        category_ids: payload.tag_ids,
                    };
                });
                editLineItems(receiptId, newlyCategorizedLineItems);
            }
            return receipt;
        } else {
            const { data } = await ReceiptService.editReceipt(
                receiptId,
                payload
            );
            if (receipt) {
                setInboxReceipts(new Map(inboxReceipts.set(receiptId, data)));
            }
            return data;
        }
    };

    const commitReceipt = async (
        receiptId: string,
        updatedReceipt?: IInboxReceipt
    ) => {
        const receipt = updatedReceipt ?? inboxReceipts.get(receiptId);
        if (!receipt) return;
        if (receipt.categories.length === 0) {
            console.error('No categories selected');
            return;
        }
        const onDelete = () => {
            setInboxReceipts((receipts) => {
                const copy = new Map(receipts);
                copy.delete(receiptId);
                return copy;
            });
            const folder = receipt.categories[0];
            setHandleSaveWhenDefined({
                status: 'success',
                message: 'Saved!',
                duration: 7000,
                link: folder
                    ? {
                          text: `View in "${folder.name}"`,
                          onClick: () => {
                              navigate('/receipts', {
                                  state: {
                                      tagId: folder.tag_id,
                                      folderName: folder.name,
                                      tagIds: [folder.tag_id],
                                  },
                              });
                          },
                      }
                    : undefined,
            });
            setTimeout(() => setHandleSaveWhenDefined(undefined), 250);
        };
        if (receipt.type === 'processing') {
            const payload: IProcessingCommitData = receipt;
            const maybeLineItems = processingLineItemChanges[receiptId];
            if (maybeLineItems) {
                payload.line_items = maybeLineItems;
            }
            // if the payload doesn't include date, put in today's date
            // await Validators.RECEIPT.validate(payload); // TODO: if we had a snackbar, we could give feedback,
            // but since we don't, below will do
            // validate:
            if (!payload.merchant) {
                payload.merchant = 'Unknown';
            }
            if (!payload.date) {
                payload.date = new Date().toISOString();
            }
            try {
                setIsSaving(true);
                await ReceiptService.commitReceiptV2(receiptId, payload);
                setIsSaving(false);
                onDelete();
            } catch (err) {
                console.error('Error committing receipt', err);
            }
        } else {
            onDelete();
        }
    };

    const getLineItems = async (
        receiptId: string
    ): Promise<(ILineItem | IProcessingLineItem)[]> => {
        const receipt = inboxReceipts.get(receiptId);
        if (!receipt) return [];
        if (receipt.type === 'processing') {
            const localChanges = processingLineItemChanges[receiptId];
            if (localChanges) return localChanges;
            return await ReceiptService.getProcessingLineItems(receiptId);
        } else {
            return await ReceiptService.getLineItems(receiptId);
        }
    };

    const editLineItems = async (
        receiptId: string,
        lineItems: (ILineItem | IProcessingLineItem)[]
    ) => {
        const receipt = inboxReceipts.get(receiptId);
        if (!receipt) return;
        if (receipt.type === 'processing') {
            // Because processing line items are only stored when we commit it,
            // we can store the line items in state, then when it comes time to save the receipt, pass them in if they exist
            setProcessingLineItemChanges((changes) => {
                return {
                    ...changes,
                    [receiptId]: lineItems as IProcessingLineItem[],
                };
            });
        } else {
            await ReceiptService.editReceipt(receiptId, {
                line_items: lineItems as ILineItem[],
            });
        }
    };

    const addLineItem = async (
        receiptId: string
    ): Promise<ILineItem | IProcessingLineItem | undefined> => {
        const receipt = inboxReceipts.get(receiptId);
        if (!receipt) return;
        const newItem: IProcessingLineItem = {
            item_id: uniqueId(),
            total: 0,
            item: '',
            category_ids: receipt.categories.map((c) => c.tag_id),
        };
        if (receipt.type === 'processing') {
            setProcessingLineItemChanges((changes) => {
                const newLineItems = (changes[receiptId] || []).concat(newItem);
                changes[receiptId] = newLineItems;
                return changes;
            });
            return newItem;
        } else {
            const { data } = await ReceiptService.createLineItem(
                receiptId,
                newItem
            );
            return data;
        }
    };

    return (
        <InboxContext.Provider
            value={{
                inboxReceipts,
                setInboxReceipts,
                isLoadingInboxReceipts,
                deleteReceipts,
                editReceipt,
                commitReceipt,
                getLineItems,
                editLineItems,
                addLineItem,
                folders,
                spaces,
                isSaving,
                handleSaveWhenDefined,
                refetch,
                processingLineItemChanges,
            }}
        >
            {props.children}
        </InboxContext.Provider>
    );
};

export const useInbox = () => useContext(InboxContext);
