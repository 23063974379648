import { AxiosResponse } from 'axios';
import axios, { axios_v1 } from '../../util/axios';
import { IStandardResponse } from '../../types/API';
import { Experience } from '../receipts/types';
import {
    IAuthResponse,
    IStandardResponseAlt,
    IUser,
    IUserResponse,
    IRegisterResponse,
    ICheckAccountResponse,
    IRequestMobileChangeRepsonse,
    IRequestSignupUrl,
    IOauthLoginResponse,
    IMagicAuthResponse,
    IAccountRegisterPhonelessResponse,
} from './types';

export default class AuthService {
    public static login(
        username: string,
        password: string
    ): Promise<IAuthResponse> {
        return axios
            .post('/auth/token', { username, password })
            .then((value: AxiosResponse<IAuthResponse>) => value.data);
    }

    public static userInfo(): Promise<IUser> {
        return axios
            .get('/auth/user')
            .then((value: AxiosResponse<IUserResponse>) => value.data.data);
    }

    public static setUserEmail(email: string): Promise<IStandardResponseAlt> {
        return axios
            .post('/auth/email', { email })
            .then((value: AxiosResponse<IStandardResponseAlt>) => value.data);
    }

    public static setPassword(
        mobile: string,
        code: string,
        password?: string
    ): Promise<IAuthResponse> {
        return axios
            .post('/auth/password', { mobile, code, password })
            .then((value: AxiosResponse<IAuthResponse>) => value.data);
    }

    public static requestPasswordReset(
        mobile: string
    ): Promise<IStandardResponseAlt> {
        return axios
            .post('/auth/password/reset', { mobile })
            .then((value: AxiosResponse<IStandardResponseAlt>) => value.data);
    }

    public static async register(
        mobile: string,
        name?: string,
        shareToken?: string,
        organizationToken?: string,
        experience?: Experience,
        email?: string
    ): Promise<IRegisterResponse> {
        return axios
            .post(`/auth/register`, {
                mobile,
                name: name || undefined,
                acquired_from: 'web',
                share_token: shareToken || undefined,
                organization_token: organizationToken || undefined,
                experience,
                email,
            })
            .then((value) => {
                return value.data;
            });
    }

    public static createAccountPhoneless(
        name?: string,
        shareToken?: string,
        referralToken?: string
    ): Promise<IAccountRegisterPhonelessResponse> {
        return axios
            .post('/auth/user/create', {
                acquired_from: 'web',
                name,
                share_token: shareToken,
                referral_token: referralToken,
            })
            .then(
                (value: AxiosResponse<IAccountRegisterPhonelessResponse>) =>
                    value.data
            );
    }

    public static getToken(
        username: string,
        password: string
    ): Promise<IAuthResponse> {
        return axios
            .post('/auth/token', { username, password })
            .then((value: AxiosResponse<IAuthResponse>) => {
                return value.data;
            });
    }

    public static verifyAccount(
        mobile: string,
        code: string,
        shareToken?: string,
        organizationToken?: string
    ): Promise<IStandardResponse> {
        return axios
            .post('/auth/verify', {
                mobile,
                code,
                share_token: shareToken,
                organization_token: organizationToken,
            })
            .then((value: AxiosResponse<IStandardResponse>) => value.data);
    }

    public static checkAccount(mobile: string) {
        return axios_v1
            .post('/auth/login/mobile', { mobile })
            .then((value: AxiosResponse<ICheckAccountResponse>) => value.data);
    }

    public static setPasswordWithCode(code: string, password: string) {
        return axios
            .post('/auth/password', { code, password })
            .then((value: AxiosResponse<ICheckAccountResponse>) => value.data);
    }

    public static async requestMobileChange(
        mobile: string
    ): Promise<IRequestMobileChangeRepsonse> {
        return axios
            .post('/auth/request_mobile_change', { mobile })
            .then(
                (value: AxiosResponse<IRequestMobileChangeRepsonse>) =>
                    value.data
            );
    }

    public static async requestSignupUrl(
        mobile: string,
        originWebUrl: string,
        shareToken?: string,
        organizationToken?: string
    ): Promise<IRequestSignupUrl> {
        return axios
            .post('/auth/request_signup_url', {
                mobile,
                origin_web_url: originWebUrl,
                share_token: shareToken,
                organization_token: organizationToken,
            })
            .then((value: AxiosResponse<IRequestSignupUrl>) => value.data);
    }

    public static async validateAppleAuth(
        idToken?: string,
        fullName?: {
            firstName: string;
            lastName: string;
        },
        setUserActive?: boolean,
        onlyIfExistingUser?: boolean
    ) {
        return axios
            .post('/auth/oauth/apple', {
                id_token: idToken,
                full_name: fullName,
                acquired_from: 'web',
                set_user_active: setUserActive,
                only_if_existent: onlyIfExistingUser,
            })
            .then((value: AxiosResponse<IOauthLoginResponse>) => value.data);
    }

    public static async getMagicAuthToken(
        slug: string
    ): Promise<IMagicAuthResponse> {
        return axios
            .post('/auth/magiclink/get', { token: slug })
            .then((value: AxiosResponse<IMagicAuthResponse>) => value.data);
    }
}
