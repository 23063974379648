import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SharedReviewService from '../services/sharedreviews/service';

export const useAsyncEffect = (
    effect: () => Promise<void>,
    deps: any[] | undefined = undefined
) => {
    useEffect(() => {
        (async () => {
            await effect();
        })();
    }, deps);
};

export const useDeepCompareMemoize = (value: any) => {
    const ref = useRef();
    if (!_.isEqual(ref.current, value)) {
        ref.current = value;
    }
    return ref.current;
};

export const useDeepCompareEffect = (effect: VoidFunction, deps: any[]) => {
    useEffect(effect, deps.map(useDeepCompareMemoize));
};

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export const useValidateGuestToken = () => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const { id } = useParams();
    const persistToken: string | null = localStorage.getItem('guestToken');

    useEffect(() => {
        SharedReviewService.validateGuestToken(id, persistToken)
            .then((res) => {
                setIsValid(res);
                setIsFetching(false);
                setIsError(false);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    setIsValid(false);
                } else {
                    setIsError(true);
                }
                setIsFetching(false);
            });
    }, [id, persistToken]);

    return { isValid, isFetching, isError };
};
