import { useState } from 'react';
import SurveyButton from './SurveyButton';
import { SurveyContainer } from './styles';

export type ISurveyChoice = { text: string; value: string };

type iSurveyProps = {
    choices: ISurveyChoice[];
    onSelect: (value: string) => void;
    style?: React.CSSProperties;
    defaultChoiceValue?: string;
};

const Survey = ({
    choices,
    onSelect,
    style,
    defaultChoiceValue,
}: iSurveyProps) => {
    const defaultChoice = choices.find((c) => c.value === defaultChoiceValue);
    const [selected, setSelected] = useState<ISurveyChoice | undefined>(
        defaultChoice || undefined
    );

    const onTouchChoice = (c: ISurveyChoice) => {
        setSelected(c);
        onSelect(c.value);
    };

    return (
        <SurveyContainer style={{ ...style }}>
            {choices.map((choice) => {
                return (
                    <SurveyButton
                        key={choice.value}
                        choice={choice}
                        onSelect={onTouchChoice}
                        isSelected={selected?.value === choice.value}
                    />
                );
            })}
        </SurveyContainer>
    );
};

export default Survey;
