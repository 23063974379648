import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LogoBlue from '../../../assets/landing/logo_blue.svg';
import LogoIcon from '../../../assets/landing/logo_icon.svg';
import { Body } from '../../../components/Text';
import theme from '../../../theme';
import { HEADER_HEIGHT } from '../../../util/constants';
import Devices, { number } from '../../../util/devices';
import { onWebAppLandingPageCTAClick } from '../../../tracking/trackers';
import { isIOS } from '../../../util/isIOS';
import { getURLWithoutParams } from '../../../util/url';

const SectionStickyHeaderLogo = (props: {
    pageId: string;
    expName?: string;
    headerMode?: 'login' | 'signup';
    ctaLabel?: string;
    children?: JSX.Element | JSX.Element[] | null;
    onClick?: () => void;
}) => {
    const navigate = useNavigate();

    const onLoginClick = props.onClick
        ? props.onClick
        : () => {
              if (props.headerMode === 'login') {
                  navigate('/login');
              } else {
                  onWebAppLandingPageCTAClick({
                      pageId: props.pageId,
                      expName: props.expName,
                      ctaPosition: 'navbar',
                      url: getURLWithoutParams(),
                      isIOS,
                  });

                  navigate('/welcome/sign-up');
              }
          };

    return (
        <HeaderContainerStyle
            style={{
                boxShadow: theme.defaultShadow,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: number.LAPTOP,
                }}
            >
                <HeaderLogoAStyle
                    href={props.onClick ? '#' : '/'}
                    onClick={props.onClick}
                >
                    <HeaderLogoStyle
                        src={LogoIcon}
                        alt="white leaf inside blue square logo"
                    />
                    <HeaderNameStyle src={LogoBlue} alt={'SimplyWise'} />
                </HeaderLogoAStyle>
                {props.headerMode === 'login' ? (
                    <LoginButtonStyle onClick={onLoginClick}>
                        <BodyStyle>{'Log in'}</BodyStyle>
                    </LoginButtonStyle>
                ) : props.children ? (
                    props.children
                ) : (
                    <LoginButtonStyle onClick={onLoginClick}>
                        <BodyStyle>
                            {props.ctaLabel ?? 'Start for free'}
                        </BodyStyle>
                    </LoginButtonStyle>
                )}
            </div>
        </HeaderContainerStyle>
    );
};

export const HeaderContainerStyle = styled.div`
    display: flex;
    z-index: 100;
    background-color: ${theme.colors.white};
    min-height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    padding: 10px 16px;
    justify-content: center;

    @media ${Devices.TABLET_SM} {
        padding: 10px 45px;
    }
`;

export const HeaderLogoStyle = styled.img`
    max-width: 32px;
    width: calc(100vw - 300px);
    display: none;
    @media ${Devices.MOBILE_SM} {
        display: block;
    }
`;

export const HeaderNameStyle = styled.img`
    max-width: 120px;
    max-height: 28px;
    margin-left: 8px;
`;

export const HeaderLogoAStyle = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
`;

export const LoginButtonStyle = styled.button`
    display: flex;
    color: ${theme.colors.white};
    text-decoration: none;
    border-radius: ${5}px;
    background-color: ${theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    border: none;

    padding: 8px 16px;

    &:hover {
        text-decoration: none;
        color: ${theme.colors.white};
        background-color: ${theme.colors.secondary};
    }
`;

export const BodyStyle = styled(Body)`
    font-weight: bold !important;
`;

export default SectionStickyHeaderLogo;
