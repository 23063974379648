import LogoWhite from '../../assets/landing/logo_white.svg';
import CTAButton from '../../components/Buttons/CTAButton';
import { BulletPoint } from '../../components/Text';
import theme from '../../theme';
import { FAQ_LINK } from '../../util/constants';
import {
    FooterContainer,
    FooterLinksColumn,
    FooterDownloadContainer,
    FooterLink,
} from './styles';

export enum IFooterLink {
    about = 'about',
    privacy = 'privacy',
    terms = 'terms',
    contact = 'contact',
    confidence = 'confidence',
    blog = 'blog',
    faq = 'faq',
}

type IFooterProps = {
    mode?: 'normal' | 'download';
    hideFooterLinks?: IFooterLink[];
};

const Footer = (props: IFooterProps) => {
    // Get object of all footer links we want to include
    const footerObj: { -readonly [key in keyof typeof IFooterLink]: boolean } =
        {
            about: false,
            privacy: false,
            terms: false,
            contact: false,
            confidence: false,
            blog: false,
            faq: false,
        };

    Object.values(IFooterLink).forEach((s) => {
        if (!props.hideFooterLinks || !props.hideFooterLinks.includes(s)) {
            footerObj[s] = true;
        }
    });

    return (
        <FooterContainer>
            {props.mode === 'download' ? (
                <FooterDownloadContainer>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: 40,
                            marginBottom: 20,
                            minWidth: 200,
                            maxWidth: 300,
                            justifyContent: 'center',
                        }}
                    >
                        <CTAButton text={'Try Now!'} />
                    </div>
                </FooterDownloadContainer>
            ) : (
                <a href="/" style={{ marginTop: 40 }}>
                    <img
                        src={LogoWhite}
                        alt=""
                        width={170}
                        height={28}
                        style={{ marginTop: 10 }}
                    />
                </a>
            )}
            <FooterLinksColumn>
                {footerObj[IFooterLink.about] ? (
                    <FooterLink href={'/about'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            About Us
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {footerObj[IFooterLink.faq] ? (
                    <FooterLink href={FAQ_LINK}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            FAQ
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {footerObj[IFooterLink.privacy] ? (
                    <FooterLink href={'/privacy'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Privacy Policy
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {footerObj[IFooterLink.terms] ? (
                    <FooterLink href={'/terms'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Terms and Conditions
                        </BulletPoint>
                    </FooterLink>
                ) : null}
                {/* {footerObj[IFooterLink.confidence] ? (
                    <FooterLink
                        href={
                            'https://www.simplywise.com/blog/retirement-confidence-index'
                        }
                    >
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Confidence Index
                        </BulletPoint>
                    </FooterLink>
                ) : null} */}
                {footerObj[IFooterLink.blog] ? (
                    <FooterLink href={'https://www.simplywise.com/blog/'}>
                        <BulletPoint style={{ color: theme.colors.white }}>
                            Blog
                        </BulletPoint>
                    </FooterLink>
                ) : null}
            </FooterLinksColumn>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 20,
                    alignItems: 'center',
                }}
            >
                <BulletPoint
                    style={{ color: theme.colors.white, textAlign: 'center' }}
                >
                    Free Tools
                </BulletPoint>
                <a
                    href={`/whats-this-charge`}
                    style={{ textDecorationColor: theme.colors.white }}
                >
                    <BulletPoint style={{ color: theme.colors.white }}>
                        What's this charge?
                    </BulletPoint>
                </a>
            </div>
            <BulletPoint style={{ color: theme.colors.white }}>
                Contact us
            </BulletPoint>
            <a
                aria-label="SimplyWise support email"
                href={`mailto:support@simplywise.com`}
                style={{ textDecorationColor: theme.colors.white }}
            >
                <BulletPoint style={{ color: theme.colors.white }}>
                    support@simplywise.com
                </BulletPoint>
            </a>
            <BulletPoint
                style={{ color: theme.colors.disabled, marginTop: 40 }}
            >
                &copy; 2021 SimplyWise, Inc. All rights reserved.
            </BulletPoint>
        </FooterContainer>
    );
};

export default Footer;
