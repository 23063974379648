import { VariationTextElement } from '../configuration/types';

export const VariationDescription = (props: VariationTextElement) => {
    if (props.type !== 'TextElement' || !props.render) {
        return null;
    }

    const lines = props.text.split('\n').map((line, index) => {
        return (
            <p style={{ margin: 0 }} key={index}>
                {line}
            </p>
        );
    });

    return <>{lines}</>;
};
