import { Suspense, lazy } from 'react';
import {
    CTAContainer,
    HeaderWrapper,
    LandingBody,
    LeftSection,
    PageContainer,
    SectionWrapper,
} from '../../../screens/Landing/styles';
import Footer from '../../../screens/Landing/Footer';
// import { CheckApplePayAvailability } from '../../Experiments/CheckApplePayAvailability';
import { heroGradient1 } from '../../../util/styleConstants';
import { SectionHeader, SectionSubtitle } from '../../Text';
import { VariationHeader } from '../elements/VariationHeader';
import { VariationDescription } from '../elements/VariationDescription';
import { VariationSecondaryVisual } from '../elements/VariationSecondaryVisual';
import { VariationCallToAction } from '../elements/VariationCallToAction';
import {
    MAIN_VISUAL_DIMENSIONS,
    VariationMainVisual,
} from '../elements/VariationMainVisual';
import SectionStickyHeaderLogo from '../elements/SectionStickyHeaderLogo';
import { VariationCallToActionSmall } from '../elements/VariationCallToActionSmall';
import LandingSection from '../../../screens/Landing/LandingSection';
import theme from '../../../theme';
import { PageVariation } from './types';
import {
    AnimatedSectionContainer,
    AnimatedSectionContainerInner,
} from './styles';

const ReportsDemo = lazy(
    () => import('../../../assets/animations/svGator/ReportsDemo')
);
const FoldersDemo = lazy(
    () => import('../../../assets/animations/svGator/FoldersDemo')
);
const OcrDemo = lazy(
    () => import('../../../assets/animations/svGator/OcrDemo')
);
const EmailDemo = lazy(
    () => import('../../../assets/animations/svGator/EmailDemo')
);
const QuickbooksDemo = lazy(
    () => import('../../../assets/animations/svGator/QuickbooksSync')
);
const TextingDemo = lazy(
    () => import('../../../assets/animations/svGator/Texting')
);
const ConstructionLandingTrack = lazy(
    () => import('../../../assets/animations/svGator/ConstructionLandingTrack')
);
const ConstructionLandingWriteOffs = lazy(
    () =>
        import(
            '../../../assets/animations/svGator/ConstructionLandingWriteOffs'
        )
);
const ConstructionLandingEstimate = lazy(
    () =>
        import('../../../assets/animations/svGator/ConstructionLandingEstimate')
);

export enum SectionAnimation {
    REPORTS = 'reports',
    FOLDERS = 'folders',
    OCR = 'ocr',
    EMAIL = 'email',
    QUICKBOOKS = 'quickbooks',
    TEXTING = 'texting',
    CON_BIDS = 'con_bids',
    CON_TRACK = 'con_track',
    CON_WRITEOFFS = 'con_writeoffs',
}

const DEMO_ANIMATIONS: {
    [key in SectionAnimation]: JSX.Element;
} = {
    [SectionAnimation.REPORTS]: <ReportsDemo />,
    [SectionAnimation.FOLDERS]: <FoldersDemo />,
    [SectionAnimation.OCR]: <OcrDemo />,
    [SectionAnimation.EMAIL]: <EmailDemo />,
    [SectionAnimation.QUICKBOOKS]: <QuickbooksDemo />,
    [SectionAnimation.TEXTING]: <TextingDemo />,
    [SectionAnimation.CON_BIDS]: <ConstructionLandingEstimate />,
    [SectionAnimation.CON_TRACK]: <ConstructionLandingTrack />,
    [SectionAnimation.CON_WRITEOFFS]: <ConstructionLandingWriteOffs />,
};

export const Variation = (props: PageVariation) => {
    return (
        <>
            <SectionStickyHeaderLogo
                expName={props.variationName}
                pageId={props.baseName}
                headerMode={props.headerMode}
            >
                {props.tabCallToAction ? (
                    <VariationCallToActionSmall
                        {...props.tabCallToAction}
                        baseName={props.baseName}
                        variationName={props.variationName}
                    />
                ) : null}
            </SectionStickyHeaderLogo>
            {/* <CheckApplePayAvailability landingPageId={'todo'} /> */}
            <PageContainer>
                <SectionWrapper style={{ backgroundImage: heroGradient1 }}>
                    <HeaderWrapper>
                        <LeftSection>
                            <SectionHeader>
                                <VariationHeader {...props.header} />
                            </SectionHeader>

                            <SectionSubtitle
                                style={{
                                    marginBottom: 40,
                                }}
                            >
                                <VariationDescription {...props.description} />
                            </SectionSubtitle>
                            <CTAContainer>
                                <VariationSecondaryVisual
                                    {...props.secondaryVisual}
                                />
                                <VariationCallToAction
                                    {...props.callToAction}
                                    baseName={props.baseName}
                                    variationName={props.variationName}
                                />
                            </CTAContainer>
                        </LeftSection>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: 40,
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: theme.roundness,
                                    overflow: 'hidden',
                                    width: MAIN_VISUAL_DIMENSIONS.width,
                                    height: MAIN_VISUAL_DIMENSIONS.height,
                                }}
                            >
                                {!process.isServer && (
                                    <VariationMainVisual
                                        {...props.mainVisual}
                                    />
                                )}
                            </div>
                        </div>
                    </HeaderWrapper>
                </SectionWrapper>
            </PageContainer>
            <LandingBody>
                {props.howItWorks.component}
                {props.trustedBy.component}
                {props.sectionsTitle?.component}
                {props.sections ? (
                    <AnimatedSectionContainer>
                        <AnimatedSectionContainerInner>
                            {props.sections.map((section, i) => {
                                const isRight = i % 2 === 0;
                                return (
                                    <LandingSection
                                        mode={isRight ? 'right' : 'left'}
                                        header={section.header}
                                        section={section.subheader}
                                        asset={
                                            <div
                                                style={{
                                                    width: 400,
                                                    height: 400,
                                                }}
                                            >
                                                {!process.isServer ? (
                                                    <Suspense>
                                                        {
                                                            DEMO_ANIMATIONS[
                                                                section
                                                                    .animation
                                                            ]
                                                        }
                                                    </Suspense>
                                                ) : null}
                                            </div>
                                        }
                                    />
                                );
                            })}
                        </AnimatedSectionContainerInner>
                    </AnimatedSectionContainer>
                ) : null}
                {props.pricing.component}
                {props.reviews.component}
                {props.reviewsVertical.component}
                {props.singlePricing.component}
                {props.finalCallToActionHeader || props.finalCallToAction ? (
                    <SectionWrapper
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundColor: '#F5F3E',
                            ...(props.finalCallToActionHeader
                                ? { padding: '200px 0' }
                                : {}),
                        }}
                    >
                        {props.finalCallToActionHeader ? (
                            <SectionHeader
                                style={{
                                    marginBottom: 80,
                                }}
                            >
                                Get Started
                            </SectionHeader>
                        ) : null}
                        {props.finalCallToAction ? (
                            <VariationCallToAction
                                {...props.finalCallToAction}
                                isFooter
                                baseName={props.baseName}
                                variationName={props.variationName}
                            />
                        ) : null}
                    </SectionWrapper>
                ) : null}
            </LandingBody>
            <Footer mode={'normal'} hideFooterLinks={[]} />
        </>
    );
};
