import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { IEmailProviderResponse, IGmailImportResultResponse } from './types';

export default class EReceiptsService {
    public static getEmailProvider(
        email: string
    ): Promise<IEmailProviderResponse> {
        return axios
            .post('/receipts/ereceipts/get-email-provider', { email })
            .then((value: AxiosResponse<IEmailProviderResponse>) => value.data);
    }

    public static requestHistoricalEmails(
        email: string
    ): Promise<IEmailProviderResponse> {
        return axios
            .post('/receipts/ereceipts/get-historical-receipts', { email })
            .then((value: AxiosResponse<IEmailProviderResponse>) => value.data);
    }

    public static gmailImportResult(
        email: string
    ): Promise<IGmailImportResultResponse> {
        return axios
            .post('/receipts/ereceipts/gmail-import-result', { email })
            .then(
                (value: AxiosResponse<IGmailImportResultResponse>) => value.data
            );
    }
}
