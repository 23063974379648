import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import {
    IGetCostEstimationResponse,
    IGetXlsxExportResponse,
    IUploadImageResponse,
} from './types';

export default class PublicService {
    public static uploadImage(image: Blob): Promise<IUploadImageResponse> {
        const data = new FormData();
        data.append('file', image);
        return axios
            .post('/public/upload-image', data)
            .then((value: AxiosResponse<IUploadImageResponse>) => {
                return value.data;
            });
    }

    public static getCostEstimation(
        image_id: string,
        prompt: string
    ): Promise<IGetCostEstimationResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        data.append('prompt', prompt);
        data.append('format', 'csv');
        data.append('visitor_id', localStorage.getItem('visitor_id') || '');
        return axios
            .post('/public/get-cost-estimation', data)
            .then((value: AxiosResponse<IGetCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getImageAnalyze(
        image_id: string
    ): Promise<IGetCostEstimationResponse> {
        const data = new FormData();
        data.append('image_id', image_id);
        return axios
            .post('/public/analyze-image', data)
            .then((value: AxiosResponse<IGetCostEstimationResponse>) => {
                return value.data;
            });
    }

    public static getXlsxExport(
        csv_data: string
    ): Promise<IGetXlsxExportResponse> {
        const data = new FormData();
        data.append('csv_data', csv_data);
        return axios
            .post('/public/get-xlsx-export', data)
            .then((value: AxiosResponse<IGetXlsxExportResponse>) => {
                return value.data;
            });
    }
}
