import { useLocation } from 'react-router-dom';
import { VariationButtonElement } from '../configuration/types';
import { onWebAppLandingPageCTAClick } from '../../../tracking/trackers';
import { isIOS } from '../../../util/isIOS';
import multiChannelEvents from '../../../tracking/multiChannelEvents';
import TextingCollectionSmallCTA from '../../Onboarding/TextingCollectionSmallCTA';
import randId from '../../../util/randId';
import { BodyStyle, LoginButtonStyle } from './SectionStickyHeaderLogo';
import { useCTAAction } from './useCTAAction';

export const VariationCallToActionSmall = (
    props: VariationButtonElement & { baseName: string; variationName: string }
) => {
    const location = useLocation() as any;
    const ctaAction = useCTAAction(props.action);

    const onCTAClick = () => {
        ctaAction();
        multiChannelEvents.addToCart();
        onWebAppLandingPageCTAClick({
            pageId: props.baseName,
            expName: props.variationName,
            ctaPosition: props.assignedPosition,
            isIOS,
            url: location.pathname,
        });
    };

    if (props.action === 'TeamsCollectionSignup') {
        return (
            <TextingCollectionSmallCTA
                label={props.label}
                onCTAClick={onCTAClick}
            />
        );
    }

    return (
        <LoginButtonStyle onClick={() => onCTAClick()}>
            <BodyStyle>{props.label}</BodyStyle>
        </LoginButtonStyle>
    );
};
