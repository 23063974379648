import styled from 'styled-components';
import { number } from '../../../util/devices';

export const AnimatedSectionContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 100px;
    padding-left: 40px;
    padding-right: 40px;
`;

export const AnimatedSectionContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${number.LAPTOP}px;
    justify-self: center;
`;
