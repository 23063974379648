import { useEffect, useState } from 'react';
import { IReceipt } from '../../services/receipts/types';
import { ALL_DOCUMENTS } from '../../util/constants';
import ReceiptService from '../../services/receipts/service';
import { onWebAppSearch } from '../../tracking/trackers';

const useReceiptSearch = (
    // can pass indexedReceipts if you want the result to NOT be partial receipts
    // But be careful because if you pass this before indexedReceipts is initialized, search results will be empty
    indexedReceipts?: Map<string, IReceipt> | undefined,
    folderId?: string,
    folderName?: string,
    source?: 'home' | 'folder',
    folderIds?: string[]
) => {
    const [search, setSearch] = useState<string>('');
    const [searchResults, setSearchResults] = useState<IReceipt[]>([]);
    const [searchIsComplete, setSearchIsComplete] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const handleReset = () => {
        setSearch('');
        setSearchResults([]);
        setIsSearching(false);
        setSearchIsComplete(false);
    };

    const handleSearch = async () => {
        if (search.length === 0) {
            handleReset();
            return;
        }
        onWebAppSearch({
            source: source || 'folder',
            folder: folderName || ALL_DOCUMENTS,
            query: search,
        });
        setIsSearching(true);
        setSearchIsComplete(false);
        ReceiptService.search(
            '-date',
            search,
            undefined,
            folderId === ALL_DOCUMENTS ? undefined : folderId,
            folderIds,
            1,
            1000
        )
            .then((res) => {
                // need to check if search is empty; possible "then" fires after we cleared the search
                let currSearch = '';
                setSearch((curr) => {
                    currSearch = curr;
                    return curr;
                });
                if (currSearch === '') return;
                const rs = res.data.results.receipts;
                if (indexedReceipts) {
                    const results = rs.reduce((acc: IReceipt[], hit) => {
                        const id = hit.receipt_id;
                        const receipt = indexedReceipts.get(id);
                        if (receipt) {
                            acc.push(receipt);
                        }
                        return acc;
                    }, []);
                    setSearchResults(results);
                } else {
                    // NOTE: These are actually PARTIAL receipts, but has enough data to display within a list
                    const rsWithNoDates = rs.map((r) => ({
                        ...r,
                        // @ts-ignore - search endpoint formats dates like 1652414400 instead of "2023-03-23T04:00:00Z"
                        date: r.date && new Date((r.date as number) * 1000),
                    }));
                    setSearchResults(rsWithNoDates as IReceipt[]);
                }
            })
            .finally(() => {
                setIsSearching(false);
                setSearchIsComplete(true);
            });
    };

    useEffect(() => {
        if (search.length === 0) {
            handleReset();
        }
    }, [search]);

    return {
        search,
        setSearch: (s: string) => {
            setSearch(s);
        },
        searchIsComplete,
        searchResults,
        isSearching,
        handleSearch,
    };
};

export default useReceiptSearch;
