import _ from 'lodash';
import { IReceipt, IUserTag } from '../../services/receipts/types';
import { ISharedReview } from '../../services/sharedreviews/types';
import { ALL_DOCUMENTS } from '../../util/constants';

const allDocuments: IUserTag = {
    tag_id: ALL_DOCUMENTS,
    name: ALL_DOCUMENTS,
    icon_id: 'all-documents',
    user_id: 0, // assuming we won't use these fields
    shared_users: [], // assuming we won't use these fields
};

/**
 * Returns sorted folders that should be included in shared review
 * If shared review is for all documents, it returns all folders that have documents inside them, including uncategorized
 */
export const getSharedReviewCategories = (
    sharedReview: ISharedReview | undefined,
    receipts: Map<string, IReceipt>
): IUserTag[] => {
    if (!sharedReview) return [];
    let allCategories = sharedReview.categories
        ? sharedReview.categories.filter((c) => c.tag_id !== ALL_DOCUMENTS) // filter out All Documents (we add it later)
        : [];

    const iterableReceipts = Array.from(receipts.values());

    // check if there are uncategorized receipts so we can add the "All Documents" tab
    const hasUncategorizedReceipts =
        iterableReceipts.find((rec) => rec.categories.length === 0) !==
        undefined;

    // in the case that we're exporting all documents, we should list all folders that have documents inside them
    if (allCategories.length === 0) {
        let hasUncategorizedReceipts = false;
        // get all categories that are included in the shared review receipts
        const categoriesFromReceipts = iterableReceipts.flatMap((receipt) => {
            if (receipt.categories.length === 0) {
                hasUncategorizedReceipts = true;
            }
            return receipt.categories.map((cat) => cat);
        });
        const noDuplicateCategories = _.uniqBy(
            categoriesFromReceipts,
            (c) => c.tag_id
        );
        const tagsToCategoryFilter: IUserTag[] = noDuplicateCategories.map(
            (t) => ({
                tag_id: t.tag_id,
                name: t.name,
                icon_id: t.icon_id || '',
                space_id: t.space_id,
                user_id: 0,
                shared_users: [],
            })
        );
        // If true, add uncategorized folder
        if (hasUncategorizedReceipts) {
            tagsToCategoryFilter.push({
                tag_id: 'uncategorized',
                name: 'Uncategorized',
                icon_id: '',
                space_id: undefined,
                user_id: 0,
                shared_users: [],
            });
        }
        allCategories = tagsToCategoryFilter;
    }
    // sort in alphabetical order
    const sortedFolders = allCategories.sort((a, b) => {
        if (a.name > b.name) return 1;
        else return -1;
    });
    // If just one folder selected, "all documents" is not necessary
    if (sortedFolders.length !== 1 || hasUncategorizedReceipts) {
        sortedFolders.unshift(allDocuments);
    }
    return sortedFolders;
};
