import { COLUMNS, IColumn } from './constants';
import { ISortData } from '.';

const PREFS_KEY = 'prefs';

type ILocalStoragePrefsData = {
    sortData: ISortData;
    columns: IColumn[];
};

const useLocalStoragePrefs = (sharedReviewId?: string | undefined) => {
    const key = sharedReviewId ? `${PREFS_KEY}-${sharedReviewId}` : PREFS_KEY;

    const getData = () => {
        return JSON.parse(
            localStorage.getItem(key) ?? '{}'
        ) as ILocalStoragePrefsData | null;
    };
    let data = getData();

    const notStaleColumns = (data?.columns ?? []).map(
        (c) => COLUMNS.find((c2) => c2.key === c.key) ?? c
    );

    if (data?.columns) {
        data.columns = notStaleColumns;
    }

    const updateData = (sortData: ISortData, columns: IColumn[]) => {
        localStorage.setItem(key, JSON.stringify({ sortData, columns }));
    };

    return {
        data,
        updateData,
        getData,
    };
};

export default useLocalStoragePrefs;
