export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ENV = process.env.REACT_APP_ENV!;
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
export const BRANCH_KEY = process.env.REACT_APP_BRANCH_KEY;
export const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID;
export const STRIPE_PUBLISHABLE_API_KEY =
    process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY;
export const PAYPAL_CLIENT_ID = process.env
    .REACT_APP_PAYPAL_CLIENT_ID as string;
export const SHORT_IO_PUBLIC_API_KEY = process.env
    .REACT_APP_SHORT_IO_PUBLIC_API_KEY as string;
export const GOOGLE_CLIENT_ID = process.env
    .REACT_APP_GOOGLE_CLIENT_ID as string;
// export const EXPORT_SERVICE_BASE_URL = process.env.EXPORT_SERVICE_BASE_URL;
export const EXPORT_SERVICE_BASE_URL = 'https://export.simplywise.com';
