import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import theme from '../../theme';
import BackButton from '../Buttons/BackButton';
import BottomButton, { IBottomButtonProps } from '../Buttons/BottomButton';
import { OnboardingHeader, OnboardingSubheader } from '../Text';
import { ONBOARDING_HEADER_HEIGHT } from '../OnboardingBackground';

type IOnboardingCardTemplateProps = {
    topComponent?: JSX.Element;
    disableTopCircle?: boolean;
    title?: string;
    subtitle?: string;
    titleStyle?: React.CSSProperties;
    subtitleStyle?: any;
    content?: JSX.Element;
    betweenTitlesContent?: JSX.Element;
    extraKeyboardAvoidHeight?: number; // useful as negative value for amount BELOW the target input
    buttons?: IBottomButtonProps[];
    buttonReplacement?: JSX.Element;
    enableBackButton?: boolean;
    disableDisappearingEdge?: boolean;
    extraBottomHeight?: number;
    width?: string;
    style?: React.CSSProperties;
    footer?: JSX.Element;
    buttonsContainerStyle?: React.CSSProperties;
};

// Should render this as a child to "OnboardingBackground"
const OnboardingCardTemplate = ({
    topComponent,
    title,
    subtitle,
    subtitleStyle,
    content,
    betweenTitlesContent,
    buttons,
    enableBackButton,
    buttonReplacement,
    width,
    style,
    titleStyle,
    footer,
    buttonsContainerStyle,
}: IOnboardingCardTemplateProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const canGoBack = location.key !== 'default';
    const h = useRef(window.innerHeight - ONBOARDING_HEADER_HEIGHT).current;
    return (
        <div
            style={{
                position: 'relative',
                // height: h,
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    overflow: 'scroll',
                    scrollbarWidth: 'none',
                    height: '100%',
                    paddingBottom: 100,
                    ...style,
                }}
            >
                {canGoBack && enableBackButton ? (
                    <BackButton
                        onClick={() => {
                            if (canGoBack) {
                                navigate(-1);
                            }
                        }}
                    />
                ) : null}
                {topComponent ? topComponent : null}
                {title ? (
                    <OnboardingHeader
                        style={{
                            width: '90%',
                            marginTop: 20,
                            fontSize: '2rem',
                            color: theme.colors.blue100,
                            ...titleStyle,
                        }}
                    >
                        {title}
                    </OnboardingHeader>
                ) : null}
                {betweenTitlesContent || null}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: width || '80%',
                        maxWidth: 500,
                    }}
                >
                    {subtitle ? (
                        <OnboardingSubheader
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                fontWeight: '500',
                                color: theme.colors.blue60,
                                ...subtitleStyle,
                            }}
                        >
                            {subtitle}
                        </OnboardingSubheader>
                    ) : null}
                    {content ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 20,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {content}
                        </div>
                    ) : null}
                    {buttonReplacement ? (
                        <div
                            style={{
                                display: 'flex',
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            {buttonReplacement}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 20 }} />
                    {footer ? (
                        <>
                            <div style={{ marginTop: 20 }} />
                            {footer}
                        </>
                    ) : null}
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: 20,
                }}
            >
                {(buttons || []).map((button, index) => (
                    <div
                        style={{
                            ...buttonsContainerStyle,
                            width: '80%',
                        }}
                        key={index.toString()}
                    >
                        <BottomButton
                            key={button.text}
                            {...button}
                            style={{
                                marginTop: 10,
                                width: '100%',
                                ...button.style,
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OnboardingCardTemplate;
