import { NavigateOptions } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Experience } from '../services/receipts/types';
import { onWebAppReconciliationNavigation } from '../tracking/trackers';
import {
    RECONCILIATION_APP_STORE_URL,
    RECONCILIATION_APP_STORE_URL_NEW,
    RECONCILIATION_PLAY_STORE_URL,
} from './constants';
import { isIOS } from './isIOS';

/**
 * Allows us to easily change the navigation action on all ChargeFinder CTA instances
 */
const chargeFinderCTA = (
    cta: string,
    code: string | undefined
): {
    url: string;
    options: NavigateOptions;
} => {
    return reconciliationExperienceSignup();
};

// Note(jj): putting this here so we can revert to the old behavior if needed
const oldChargeFinderCTA = (cta: string, code: string | undefined) => {
    return {
        url: '/redirect-download',
        options: {
            state: {
                alternativeWebUrl: '/link/chargefinder',
                customData: {
                    cta,
                    code,
                    source: 'code',
                },
            },
        },
    };
};

const reconciliationExperienceSignup = (): {
    url: string;
    options: NavigateOptions;
} => {
    const cohort =
        Math.random() < 0.5 && isIOS ? 'app-store-new-ss' : 'app-store-old-ss';
    const visitorId = uuidv4();
    localStorage.setItem('visitor_id', visitorId); // gets picked up on by branch link provider

    onWebAppReconciliationNavigation({
        cohort,
    });

    return {
        url: `/download?experience=${Experience.RECONCILIATION}`,
        options: {
            state: {
                title: 'Start your free trial in the app!',
                subheader: undefined,
                branchAppStoreUrl:
                    cohort === 'app-store-new-ss'
                        ? RECONCILIATION_APP_STORE_URL_NEW
                        : RECONCILIATION_APP_STORE_URL,
                branchPlayStoreUrl: RECONCILIATION_PLAY_STORE_URL,
                // customData gets picked up on by branch link provider, which takes state.customData
                customData: {
                    experience: Experience.RECONCILIATION,
                    cohort,
                },
            },
        },
    };
};

const reconciliationExperienceSignupWTC = (customData: {
    origin_web_url: string | null;
    web_url: string | null;
    source: string | null;
}): {
    url: string;
    options: NavigateOptions;
} => {
    const visitorId = uuidv4();
    localStorage.setItem('visitor_id', visitorId); // gets picked up on by branch link provider

    onWebAppReconciliationNavigation({
        cohort: 'wtc',
    });

    return {
        url: `/download?experience=${Experience.RECONCILIATION_WTC}`,
        options: {
            state: {
                title: 'Start your free trial in the app!',
                subheader: undefined,
                branchAppStoreUrl: RECONCILIATION_APP_STORE_URL,
                branchPlayStoreUrl: RECONCILIATION_PLAY_STORE_URL,
                // customData gets picked up on by branch link provider, which takes state.customData
                customData: {
                    experience: Experience.RECONCILIATION_WTC,
                    ...customData,
                },
            },
        },
    };
};

const navigationActions = {
    chargeFinderCTA,
    oldChargeFinderCTA,
    reconciliationExperienceSignup,
    reconciliationExperienceSignupWTC,
};

export default navigationActions;
